import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo";

export default function Template({
                                     data, // this prop will be injected by the GraphQL query below.
                                 }) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { html } = markdownRemark
    return (
        <div className="md-page">
            <div className="container">
                <div
                    className="col-12"
                    dangerouslySetInnerHTML={{ __html: html }}
                />
            </div>
        </div>
    )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`

export const Head = ({data}) => <Seo title={data.markdownRemark.frontmatter.title} />